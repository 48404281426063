<template>
  <!-- filter area -->
  <div class="p-fluid p-grid p-jc-end print-hidden">
    <!-- right -->
    <div class="p-col-12 p-sm-3" v-if="userLevel == 'AC'">
      <Dropdown
        v-model="areaId"
        :options="areas"
        optionLabel="areaName"
        optionValue="codeId"
        placeholder="กรองข้อมูล พื้นที่"
        :filter="true"
        @change="fetchData"
      />
    </div>
    <div class="p-col-11 p-sm-2">
      <router-link to="/dashboard">
        <Button label="กลับสู่หน้าหลัก" icon="pi pi-arrow-left"
      /></router-link>
    </div>
    <div class="p-col-1">
      <Button icon="pi pi-print" @click="print" />
    </div>
  </div>
  <!-- / filter area -->
  <Fieldset>
    <template #legend>
      <i class="pi pi-check" style="fontsize: 2rem"></i>
      เศรษฐานะ (ECON)
    </template>
    <div>
      การวิเคราะห์ข้อมูลสถานะทางเศรษฐกิจของผู้สูงอายุเทียบกับศักยภาพในการทำงาน
      จะเป็นวิเคราะห์การแยกฐานะทางเศรษฐกิจของผู้สูงอายุ จำนวนสมาชิก
      และรายได้ของผู้สูงอายุ รายได้ของ
      ครอบครัวเพื่อเปรียบเทียบกับความสามารถในการทำงานได้ของผู้สูงอายุ
      โดยการวิเคราะห์ในส่วนนี้ จะเป็นการนำเสนอ
    </div>
    <div>1.ข้อมูลแสดงรายได้ของครอบครัวแยกตามช่วงอายุ และสถานการณ์ทำงาน</div>
    <div>2.ข้อมูลแสดงรายได้หลักของผู้สูงอายุแยกตามช่วงอายุ และเพศ</div>
    <div>
      3.ข้อมูลแสดงรายจ่ายต่อเดือนของผู้สูงอายุแยกตามช่วงอายุ และสถานการณ์ทำงาน
    </div>
    <div>
      4.ข้อมูลแสดงค่าเฉลี่ยรายได้ของครอบครัว
      รายได้หลักของผู้สูงอายุและรายจ่ายของครอบครัวรายเดือน
    </div>
  </Fieldset>

  <Fieldset :legend="images['income_total']">
    <img :src="plots['income_total']" :style="graphStyle" />
    <DividerWord :content="words['ECON01']" />
  </Fieldset>
  <Fieldset :legend="images['income_total_scatter']">
    <img :src="plots['income_total_scatter']" style="width: 100%" />
    <DividerWord :content="words['ECON02']" />
  </Fieldset>

  <Fieldset :legend="images['income_main_total']">
    <img :src="plots['income_main_total']" :style="graphStyle" />
    <DividerWord :content="words['ECON03']" />
  </Fieldset>
  <Fieldset :legend="images['income_main_total_scatter']">
    <img :src="plots['income_main_total_scatter']" style="width: 100%" />
    <DividerWord :content="words['ECON04']" />
  </Fieldset>

  <Fieldset :legend="images['expend_avg']">
    <img :src="plots['expend_avg']" :style="graphStyle" />
    <DividerWord :content="words['ECON05']" />
  </Fieldset>
  <Fieldset :legend="images['expend_avg_scatter']">
    <img :src="plots['expend_avg_scatter']" style="width: 100%" />
    <DividerWord :content="words['ECON06']" />
  </Fieldset>

  <Fieldset
    legend="กราฟแสดงค่าเฉลี่ยรายได้ของครอบครัว รายได้หลักของผู้สูงอายุและรายจ่ายของครอบครัวรายเดือน"
  >
    <div ref="chartMeasure" :style="graphStyle"></div>
    <DividerWord :content="words['ECON07']" />
  </Fieldset>
  <Fieldset :legend="images['member_total_scatter']">
    <img :src="plots['member_total_scatter']" style="width: 100%" />
    <DividerWord :content="words['ECON08']" />
  </Fieldset>
  <Fieldset legend=" กราฟแสดงจำนวนสมาชิกในครอบครัวแยกตามช่วงอายุ">
    <div ref="chartStackMemberTakecare" :style="graphStyle"></div>
    <DividerWord :content="words['ECON09']" />
  </Fieldset>
  <Fieldset :legend="images['member_takecare_scatter']">
    <img :src="plots['member_takecare_scatter']" style="width: 100%" />
    <DividerWord :content="words['ECON10']" />
  </Fieldset>
  <MoveToTopPage />
</template>

<script>
import axios from 'axios'
import * as _ from 'lodash'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

import { imageEncode } from '../../Utility'
import { mixins } from '../Mixins'

import MoveToTopPage from '../../components/MovetoTop'
import DividerWord from './DividerWord'

// Themes begin
am4core.useTheme(am4themes_animated)
// Themes end

export default {
  mixins: [mixins],
  layout: 'Private',
  data() {
    return {
      areaId: 'ALL',
      series: {
        avgMeasure: null
      },
      plots: {
        income_total: null,
        income_main_total: null,
        expend_avg: null,
        income_total_scatter: null,
        income_main_total_scatter: null,
        expend_avg_scatter: null,
        member_total_scatter: null,
        member_takecare_scatter: null
      },
      images: {
        income_total:
          'กราฟแสดงรายได้ต่อเดือนของครอบครัวแยกตามช่วงอายุและสถานะการทำงาน',
        income_main_total:
          'กราฟแสดงรายได้หลักของผู้สูงอายุแยกตามช่วงอายุ และเพศ',
        expend_avg:
          'กราฟแสดงรายจ่ายต่อเดือนของผู้สูงอายุแยกตามช่วงอายุ และสถานะการทำงาน',
        income_total_scatter:
          'กราฟแสดงรายได้ต่อเดือนของครอบครัวเทียบกับความสามารถในการทำงาน',
        income_main_total_scatter:
          'กราฟแสดงรายได้หลักต่อเดือนของผู้สูงอายุเทียบกับความสามารถในการทำงาน',
        expend_avg_scatter:
          'กราฟแสดงรายจ่ายต่อเดือนของครอบครัวเทียบกับความสามารถในการทำงาน',
        member_takecare_scatter:
          'กราฟแสดงสมาชิกในครอบครัวที่อยู่ในการดูแลของผู้สูงอายุเทียบกับความสามารถในการทำงาน',
        member_total_scatter:
          'กราฟแสดงจำนวนสมาชิกในครอบครัวเทียบกับความสามารถในการทำงาน'
      }
    }
  },
  computed: {
    graphStyle() {
      return {
        height: '500px'
      }
    },
    userLevel() {
      const { codeId, userLevel } = this.$store.getters['oauth/session']
      return userLevel
    },
    userArea() {
      const { codeId, userLevel, area } = this.$store.getters['oauth/session']
      if (userLevel == 'AC') {
        return this.areaId == 'ALL' ? null : this.areaId
      } else {
        return area?.codeId
      }
    },
    areas() {
      return this.$store.getters['area/getAreasHasAllLabel']
    }
  },
  watch: {
    series: {
      handler(newVal, oldVal) {
        console.log('newVal ::==', newVal)
        const { avgMeasure, countMemberTackecare } = newVal
        this.stackColumnMeasure(avgMeasure)
        this.stackMemberTakecare(countMemberTackecare)
      },
      deep: true
    }
  },
  async created() {
    try {
      await this.$store.dispatch('area/fetchAreasOrderNameTh')
      this.fetchData()
    } catch (error) {
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('word/fetchGraphWords', {
        areaId: this.userArea
      })
      //**************** get graph api*************** */
      const data = await this.$store.dispatch(
        'pivot/fetchGraphSeriesAnalytise',
        {
          url: 'ECON',
          areaId: this.userArea
        }
      )
      console.log('data ::==', data)
      this.series = data
      this.getGraphBarplot()
      //**************** get graph api*************** */
    },
    getGraphBarplot() {
      const { VUE_APP_ML_ENDPOINT } = process.env
      let delay = 500
      let idx = 0
      Object.keys(this.images).forEach((name) => {
        setTimeout(
          async () => {
            this.plots[name] = 'img/analytics/'+this.areaId+'/'+name+'.png?'+new Date().getTime();
          },
          idx == 0 ? 0 : delay + idx * 500
        )
      })
    },
    stackColumnMeasure(measure) {
      var chart = am4core.create(this.$refs.chartMeasure, am4charts.XYChart)
      chart.colors.list = [
        am4core.color('#A685E2'),
        am4core.color('#F38BA0'),
        am4core.color('#A7D0CD')
      ]

      chart.legend = new am4charts.Legend()
      chart.legend.position = 'down'
      chart.legend.paddingBottom = 20
      chart.legend.labels.template.maxWidth = 95

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      xAxis.dataFields.category = 'category'
      xAxis.renderer.cellStartLocation = 0.1
      xAxis.renderer.cellEndLocation = 0.9
      xAxis.renderer.grid.template.location = 0
      xAxis.title.text = 'ช่วงอายุ'

      var yAxis = chart.yAxes.push(new am4charts.ValueAxis())
      yAxis.min = 0
      yAxis.title.text = 'จำนวนเงิน (บาท)'

      function createSeries(value, name) {
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.dataFields.valueY = value
        series.dataFields.categoryX = 'category'
        series.name = name

        series.events.on('hidden', arrangeColumns)
        series.events.on('shown', arrangeColumns)

        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        var bullet = series.bullets.push(new am4charts.LabelBullet())
        bullet.interactionsEnabled = false
        bullet.dy = 30
        bullet.label.text = '{valueY}'
        bullet.label.fill = am4core.color('#000000')
        bullet.label.dy = -40

        return series
      }
      const {
        avgTotal1,
        avgMain1,
        avgExpand1,
        avgTotal2,
        avgMain2,
        avgExpand2,
        avgTotal3,
        avgMain3,
        avgExpand3
      } = measure

      chart.data = [
        {
          category: '60 - 65  ปี',
          first: avgTotal1,
          second: avgMain1,
          third: avgExpand1
        },
        {
          category: '66 - 70  ปี',
          first: avgTotal2,
          second: avgMain2,
          third: avgExpand2
        },
        {
          category: '> 70  ปี',
          first: avgTotal3,
          second: avgMain3,
          third: avgExpand3
        }
      ]

      createSeries('first', 'รายได้ของครอบครัว')
      createSeries('second', 'รายได้หลักของผู้สูงอายุ')
      createSeries('third', 'รายจ่ายของครอบครัว')

      function arrangeColumns() {
        var series = chart.series.getIndex(0)

        var w =
          1 -
          xAxis.renderer.cellStartLocation -
          (1 - xAxis.renderer.cellEndLocation)
        if (series.dataItems.length > 1) {
          var x0 = xAxis.getX(series.dataItems.getIndex(0), 'categoryX')
          var x1 = xAxis.getX(series.dataItems.getIndex(1), 'categoryX')
          var delta = ((x1 - x0) / chart.series.length) * w
          if (am4core.isNumber(delta)) {
            var middle = chart.series.length / 2

            var newIndex = 0
            chart.series.each(function (series) {
              if (!series.isHidden && !series.isHiding) {
                series.dummyData = newIndex
                newIndex++
              } else {
                series.dummyData = chart.series.indexOf(series)
              }
            })
            var visibleCount = newIndex
            var newMiddle = visibleCount / 2

            chart.series.each(function (series) {
              var trueIndex = chart.series.indexOf(series)
              var newIndex = series.dummyData

              var dx = (newIndex - trueIndex + middle - newMiddle) * delta

              series.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
              series.bulletsContainer.animate(
                { property: 'dx', to: dx },
                series.interpolationDuration,
                series.interpolationEasing
              )
            })
          }
        }
      }
    },
    stackMemberTakecare(healthMemberTakecare) {
      // Create chart instance
      const chart = am4core.create(
        this.$refs.chartStackMemberTakecare,
        am4charts.XYChart
      )

      // Add data
      chart.data = [
        {
          category: '0',
          value1: healthMemberTakecare?.count01,
          value2: healthMemberTakecare?.count02,
          value3: healthMemberTakecare?.count03
        },
        {
          category: '1',
          value1: healthMemberTakecare?.count11,
          value2: healthMemberTakecare?.count12,
          value3: healthMemberTakecare?.count13
        },
        {
          category: '2',
          value1: healthMemberTakecare?.count21,
          value2: healthMemberTakecare?.count22,
          value3: healthMemberTakecare?.count23
        },
        {
          category: '3',
          value1: healthMemberTakecare?.count31,
          value2: healthMemberTakecare?.count32,
          value3: healthMemberTakecare?.count33
        },
        {
          category: '4',
          value1: healthMemberTakecare?.count41,
          value2: healthMemberTakecare?.count42,
          value3: healthMemberTakecare?.count43
        },
        {
          category: '5',
          value1: healthMemberTakecare?.count51,
          value2: healthMemberTakecare?.count52,
          value3: healthMemberTakecare?.count53
        },
        {
          category: '6',
          value1: healthMemberTakecare?.count61,
          value2: healthMemberTakecare?.count62,
          value3: healthMemberTakecare?.count63
        },
        {
          category: '7',
          value1: healthMemberTakecare?.count71,
          value2: healthMemberTakecare?.count72,
          value3: healthMemberTakecare?.count73
        },
        {
          category: '8',
          value1: healthMemberTakecare?.count81,
          value2: healthMemberTakecare?.count82,
          value3: healthMemberTakecare?.count83
        },
        {
          category: '9',
          value1: healthMemberTakecare?.count91,
          value2: healthMemberTakecare?.count92,
          value3: healthMemberTakecare?.count93
        }
      ]

      chart.colors.list = [
        am4core.color('#A685E2'),
        am4core.color('#F38BA0'),
        am4core.color('#A7D0CD')
      ]

      // Create axes
      var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'category'
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.strictMinMax = true
      categoryAxis.renderer.minGridDistance = 1
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.title.text = 'จำนวนสมาชิกในครอบครัว (คน)'

      var valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      //valueAxis.renderer.inside = true
      //valueAxis.renderer.labels.template.disabled = true
      valueAxis.min = 0
      valueAxis.title.text = 'จำนวนผู้สูงอายุ (คน)'

      //valueAxis.renderer.minGridDistance = 20

      // Create series
      function createSeries(field, name) {
        // Set up series
        var series = chart.series.push(new am4charts.ColumnSeries())
        series.name = name
        series.dataFields.valueY = field
        series.dataFields.categoryX = 'category'
        series.sequencedInterpolation = true

        // Make it stacked
        series.stacked = true

        // Configure columns
        series.columns.template.width = am4core.percent(70)
        series.columns.template.tooltipText =
          '[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}'

        // Add label
        var labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.text = '{valueY}'
        labelBullet.locationY = 0.5
        labelBullet.label.hideOversized = true

        return series
      }
      createSeries('value1', '60 - 65')
      createSeries('value2', '66 - 70')
      createSeries('value3', '> 70')

      // Legend
      chart.legend = new am4charts.Legend()
      //chart.legend.labels.template.text = 'อายุ: [bold {color}]{name}[/]'
      chart.legend.labels.template.text = 'อายุ {name}[/] ปี'
    }
  },
  components: {
    MoveToTopPage,
    DividerWord
  }
}
</script>

<style>
</style>